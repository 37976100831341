/**
 * Nestable
 */

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;

  .dd-item {
    position: relative;
    display: block;

    @include clearfix;

    .dd-item-container {
      //
   
    }

    .dd-item-content {
      @include clearfix;

      margin-bottom: 10px;
      background-color: $list-group-bg;
      border: 1px solid $list-group-border;

      @include border-top-radius($list-group-border-radius);
      @include border-bottom-radius($list-group-border-radius);

      padding: 10px 15px 10px 65px;
    }

    .dd-item-label {
      line-height: 2.2;
      vertical-align: middle;
    }

    .dd-expand,
    .dd-collapse {
      background: transparent;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      margin: 15px 10px 0 35px;
    }

    .dd-handle {
      background: transparent;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      margin: 15px 10px;
      padding: 1px 6px;
      cursor: move;
    }

    .dd-expand {
      display: none;
    }
  }

  .dd-collapsed {
    .dd-expand {
      display: inline-block;
    }

    .dd-collapse {
      display: none;
    }
  }

  .dd-placeholder {
    border: 1px dashed $list-group-border;

    @include border-top-radius($list-group-border-radius);
    @include border-bottom-radius($list-group-border-radius);

    background-color: $list-group-hover-bg;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  .dd-list {
    padding-left: 30px;
  }
}

.dd-collapsed {
  .dd-list {
    display: none;
  }
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;

  .dd-item {
    .dd-item-content {
      margin-bottom: 0px;
    }
  }
}

/**
 * forms
 */

.ui-sortable-helper {
  display: table;
}

.plan_form_element_images {
  .thumbnail {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
  }

  .loading {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.plan-form-action-list-table {
  a.lightbox {
    display: inline-block;
  }

  img.thumbnail {
    display: inline-block;
    width: 32px;
    height: auto;
    margin-bottom: 0;
  }
}

.plan-form-action-list-actions {
  .btn-group {
    display: flex;
  }
}

.quebra-tabela {}

.quebra-linhas {
  td, th {}
}

tbody, thead {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

td, th {
  display: table-cell;
  vertical-align: inherit;
}

.nestedlist-locked {
  .dd-item {
    .dd-handle {
      color: #ccc;
      cursor: default;
    }
  }
}

.plan-form-action-gallery {
  .thumbnail {
    .ck-toggle-all {
      position: absolute;
      top: 10px;
      left: 30px;
    }
  }
}

th {
  a {
    display: inline-block;
    white-space: nowrap;
    line-height: 100%;

    .sort-icon {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      z-index: 1;

      .fa-sort-desc {
        position: absolute;
        left: 0;
        top: 0;
      }

      .fa {
        color: $gray-light;
      }

      .fa.active {
        color: inherit;
      }
    }
  }
}

.plan_form_element_image, .plan_form_element_file {
  .placeholder {
    min-width: 120px;
    min-height: 120px;
    color: $well-border;
    border: 2px dashed $well-border;
    background-color: $well-bg;

    a {
      display: block;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .dropzone {
    display: block;
  }

  .dropzone-active {
    border: 2px dashed $well-border;
    font-size: 3em;
    text-align: center;
    vertical-align: middle;
    height: 120px;
    width: 100%;
    line-height: 120px;
    color: $well-border;
    border: 2px dashed $well-border;
    background-color: $well-bg;

    @extend .fa;

    @extend .fa-plus;

    > div {
      display: none;
    }
  }
}

.tablesaw-advance {
  right: 5px;
  position: relative;

  a.tablesaw-nav-btn {
    background: #444444;
  }
}

.tablesaw-advance a.tablesaw-nav-btn.left:before {
  border-right: 5px solid #fff;
}

.tablesaw-advance a.tablesaw-nav-btn.right:before {
  border-left: 5px solid #fff;
}

.panel-body {
  .well {
    .form-group {
      margin-right: 0.5%;
      margin-bottom: 10px;

      label {
        width: 100%;
      }

      input {
        width: 100%;
      }

      .form-control {
        width: 100%;
      }
    }

    .form-group:last-child {
      margin-right: 0;
    }

    input[type="submit"] {
      margin-top: 13px;
    }
  }
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  padding: 8px 15px;
}

th, td {
  text-align: left;
  padding: 8px 15px;

  a {
    font-weight: 800;
    font-size: 14px;
  }
}