/*!
 * Tema Atlas
 * @theme: Atlas
 */

//
// tema verde
//
$brand-primary: #2f304c;
$brand-success: #5cb85c;
$brand-info: #003f7d;
$brand-warning: #d9534f;
$brand-danger: #d9534f;
//
//
//
$inverted-bg: #2f304c;
$inverted-bg-dark: darken($inverted-bg, 6.5%);
$inverted-color: #fff;
//
// Menu lateral
//
$side-menu-bg: $inverted-bg;
$side-menu-bg-dark: $inverted-bg-dark;
$side-menu-color: $inverted-color;
$side-menu-header-bg: $brand-primary;
$side-menu-header-color: #fff;
@import "../../../../../../vendor/plan/atlas-framework-core/src/resources/assets/sass/_base";
@import "~select2/dist/css/select2.css";
@import "~select2-bootstrap-theme/src/select2-bootstrap";
form#produtos {
    div[data-name="relacionados"] {
        .ms-selectable {
            display: none;
        }
        .ms-selection {
            float: left;
        }   
        .span5:first-of-type {
            display: none;
        }
        .ms-container {
            background-image: none !important;
        }
        input[placeholder="Selecionados"] {
            display: none;
        }
    }
}
.atlas-form {
  .select2-container {
    width: 100% !important;
  }
}
a {
  &:hover {
    color: #0098da;
  }
}
.breadcrumb {
  .active {
    a {
      color: #0098da;
    }
  }
}
.side-menu-nav {
  a:hover {
  }
  ul {
    li {
      ul {
        li {
        }
      }
    }
  }
}
.app-container {
  .side-menu {
    .side-menu-header {
      background: #27283f;
    }
    .side-menu-nav {
      ul {
        li {
          a {
            &:hover {
              color: #0098da;
            }
          }
        }
        li.active-self {
          a {
            color: #0098da;
          }
        }
      }
    }
  }
}
.table {
  td[data-title="Texto"] {
    word-break: break-word;
    max-width: 200px;
  }
  td[data-title="Link"] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px;
    display: block;
    overflow: hidden;
  }
  td {
    border-top: 0px !important;
  }
  tr {
    border-top: 1px solid #ddd;
  }
}
.chart {
  color: #616161;
  h1 {
    position: relative;
    font-size: 32px;
    color: #616161;
    &::after {
      background: #2f304c;
      position: absolute;
      bottom: -6px;
      left: 3px;
      content: "";
      height: 2px;
      width: 173px;
      font-size: 10px;
    }
  }
  h2 {
    font-size: 24px;
  }
  .progress {
    box-shadow: inset 0 1px 2px transparent;
    background-color: #ffff;
    border: 1px solid #b5b5b5;
    border-radius: 0;
    padding: 2px;
    .progress-bar {
      background-color: #2677b6;
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
    }
  }
  .content-dados {
    position: relative;
    float: left;
    width: 100%;
    color: #616161;
    font-size: 16px;
    margin-bottom: 5px;
    i {
      color: #2f304c;
      font-size: 20px;
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
  .float-right {
    float: right;
  }
}
h3.col-md-offset-2,
h3 {
  margin-left: 0 !important;
  background: #f5f5f5;
  padding: 10px;
  color: #0098da;
  font-size: 24px;
  margin-bottom: 20px;
}
.box-moderacoes-pendentes {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  h1 {
    &::after {
      bottom: -13px;
    }
  }
  .box {
    position: relative;
    float: left;
    width: 100%;
    margin: 15px 0px;
    .title {
      font-size: 24px;
      color: #616161;
    }
    a {
      font-size: 16px;
      color: #616161;
      margin-top: 10px;
      display: inline-block;
      color: #2f304c;
      i {
        left: 5px;
        position: relative;
        display: inline-block;
      }
      &:hover {
        text-decoration: none;
        i {
          text-decoration: none;
        }
      }
    }
  }
}
.tablesaw-advance {
  right: 5px;
  position: relative;
  a.tablesaw-nav-btn {
    background: #444444;
  }
}
.tablesaw-advance a.tablesaw-nav-btn.left:before {
  border-right: 5px solid #fff;
}
.tablesaw-advance a.tablesaw-nav-btn.right:before {
  border-left: 5px solid #fff;
}
.panel-body {
  .well {
    .form-group {
      margin-right: 0.5%;
      margin-bottom: 10px;
      label {
        width: 100%;
      }
      input {
        width: 100%;
      }
      .form-control {
        width: 100%;
      }
    }
    .form-group:last-child {
      margin-right: 0;
    }
    input[type="submit"] {
      margin-top: 13px;
    }
  }
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 8px 15px;
}
th,
td {
  text-align: left;
  padding: 8px 15px;
  a {
    font-weight: 800;
    font-size: 14px;
  }
}
.tab-pane {
  .form-group {
    label {
      width: 16% !important;
    }
  }
}
.panel-body .well .form-group label {
  width: 100%;
  text-align: left !important;
  padding: 0px;
  margin-left: 15px;
}
.panel-body .well .form-group label.radio-inline {
  margin-left: 0px !important;
}
.panel-body .well .form-group input {
  width: 114px;
}
table {
  img {
    max-width: 100%;
  }
}
.extrato-de-pontos-admin {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .collapse {
    width: 100%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .form-group {
    margin-right: 10px !important;
    label {
      width: auto !important;
      margin-left: 0 !important;
      display: block;
    }
  }
  .btn-exportar {
    top: 6px;
    position: relative;
    margin-left: 10px !important;
  }
  .box-total-results {
    position: relative;
    float: left;
    width: 100%;
    h3 {
      margin-bottom: 10px;
      padding: 0;
    }
  }
}
.table-extrato-de-pontos {
    table {
        font-size: 12px !important;
    }
    th a, td a {
        font-size: 13px !important;
    }
    th, td {
        padding: 8px 8px !important;
        text-align: center;
    }
}

select[readonly] {
    pointer-events: none;
    touch-action: none;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 25em !important;
}

/* RELATÓRIO MAILCHIMP */
.report-mailchimp {
  h3 {
    all: initial;
    margin-bottom: 25px;
    font-size: 21px;
    font-family: inherit;
    line-height: 1.1;
    color: inherit;
    display: block;
    margin-top: 15px;
  } 

  .text-upper {
    text-transform: uppercase;
  }

  .title-with-button-return {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .box-main-information {
    margin-bottom: 25px;
  }

  .box-other-information {
    margin-bottom: 15px;

    div {
      span:first-child {
        font-size: 20px;
        font-weight: bold;
      }

      span:last-child {
        font-size: 10px;
      }

      div {
        border: 1px solid #dcdcdc;
        padding: 20px;
        text-align: center;
        min-height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .table {
    .text-right {
      text-align: right;
    }
  }
}
.container-multicheckboxs {
  min-width: 260px;
  max-width: 260px;
  border: 1px solid #cfcfcf;
  padding: 0 5px;

  .header-multicheckboxs {
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
      font-size: 14px;
    }

    &:after{
      display: inline-block;
      content: "\25BC";
    }
  }

  .content-multicheckboxs {
    margin-top: 10px;
    display: none;
    overflow-y: scroll;
    max-height: 200px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    ul {
      padding: 0;

      li {
        list-style-type: none;

        label {
          font-weight: unset;
          font-size: 12px;
          display: flex;
          align-items: center;

          input {
            margin: 0 3px 0px 0px;
          }
        }
      }
    }
  }
}
.container-monitorings {
  margin-top: 25px;

  .text-align-center {
    text-align: center;
  }

  table {
    font-size: 13px;

    tbody {
      tr {
        td {
          .badge {
            display: inline-block;
            padding: 3px 29px;
            font-size: 12px;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: 0.25rem;
          }

          .badge.badge-success {
            color: #fff;
            background-color: #28a745;
          }

          .badge.badge-warning {
            color: #212529;
            background-color: #ffc107;
          }
        }
      }
    }
  }
}

.processing-queue-status {
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  font-size: 11px;

  &.waiting {
    background: #939393;
  }

  &.processing {
    background: #d5dd5e;
  }

  &.completed {
    background: #68cf88;
  }

  &.failed {
    background: #ff7b7b;
  }
}

/*
.container-monitorings {
  border: 1px solid #000;
  width: 725px;
  margin-top: 25px;

  .box-title {
    border-bottom: 1px solid #000000;
    background-color: #2677b6;
    color: #fff;

    h5 {
      font-size: 14px;
      margin: 0;
      padding: 7px 5px;
      font-weight: bold;
    }
  }

  .table-monitoring {
    width: 100%;

    th, td {
      border: 1px solid #e5e5e5;
      border-top: 0;
      font-size: 13px;
      padding: 3px;
      background-color: #bfbfbf;

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }

    th {
      border: 1px solid #000;
      border-top: 0;
    }

    td {
      background-color: #fff;
      font-weight: bold;

      &:last-child, &:nth-child(2n) {
        text-align: center;
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tr.warning {
      td {
        background-color:#fcf8e3;
      }
    }

    tr.success {
      td {
        background-color:#bae1d1;
      }
    }
  }
}
*/
