/**
 * 
 */

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");

$font-family-base: "Open Sans", sans-serif;

/**
 * 
 */

$navbar-height: 60px;

/**
 *
 *
 * 
 */

@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build";
@import "~bootstrap-colorpicker/src/sass/colorpicker";
@import "~font-awesome/scss/font-awesome";
@import "~jquery-ui/themes/base/all.css";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import "~select2/dist/css/select2.css";
@import "~select2-bootstrap-theme/src/select2-bootstrap";

/**
 *
 * Remove a cor de fundo amarela e a borda do autocomplete no Chrome
 * 
 */

form:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  @include transition(background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s);

  @include box-shadow(none);

  border-color: inherit;
}

/**
 * 
 * Login
 * 
 */

.login-page {
  position: absolute;
  top: 50%;
  width: 100%;

  @include translate(0, -50%);

  .login-container {
    background-color: $side-menu-bg;
    color: $side-menu-color;
    border-radius: 10px;

    h3 {
      text-align: center;
      font-size: 28px;
      padding: 20px;
      background-color: $inverted-bg-dark;
      border-radius: 10px 10px 0 0;
    }

    .alert {
      margin: 10px 30px;
    }

    .form-group,
    .checkbox {
      margin: 0 30px;
    }

    .checkbox {
      padding: 20px;
    }

    .form-group {
      position: relative;
    }

    .glyphicon {
      position: absolute;
      top: 0;
      left: 0;
      padding: 28px 16px;
    }

    input[type=text],
    input[type=password] {
      background-color: transparent;
      color: $inverted-color;
      border: none;
      border-bottom: 1px solid $inverted-color;
      border-radius: 0;
      font-size: 1.2em;
      padding-left: 65px;
      height: 65px;
    }

    button[type=submit] {
      width: 100%;
      border-radius: 0 0 10px 10px;
      font-size: 18px;
      padding: 14px;
    }
  }
}

.error-page {
  .error-page-header {
    width: 100%;
    margin-bottom: 20px;
    background-color: $brand-primary;

    a {
      color: $side-menu-header-color;
    }

    .error-page-brand {
      line-height: $navbar-height;
      font-size: 18px;
      overflow: hidden;
      display: block;
      width: 100%;
      height: $navbar-height;
      padding: 0;
      text-decoration: none;

      .title {
        display: inline-block;
        margin-left: -10px;
      }
    }
  }

  .error-page-logo {
    display: inline-block;
    width: $navbar-height;

    img {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin: 14px;
    }
  }
}

/**
 * 
 * Estrutura bÔö£├¡sica
 * - Menu Lateral
 * - Barra Topo
 * 
 */

.app-container {
  &.navbar-animated {
    .navbar-top {
      @include transition(padding 0.25s);

      .navbar-expand-toggle,
      .navbar-right-expand-toggle {
        @include transition(all 0.25s);
      }
    }

    .side-menu {
      @include transition(width 0.25s);
    }

    .content-container {
      @include transition(margin 0.25s);
    }
  }

  position: relative;
  min-height: 100%;

  .navbar-header {
    position: relative;
  }

  .navbar-top {
    padding-left: 250px;

    .navbar-expand-toggle,
    .navbar-right-expand-toggle {
      background-color: transparent;
      border: none;
      font-size: 18px;
      margin: 16px;
    }

    .navbar-right-expand-toggle {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }

  .side-menu {
    z-index: $zindex-navbar-fixed + 1;
    position: fixed;
    overflow-y: auto;
    width: 250px;
    height: 100%;

    .side-menu-header {
      width: 100%;

      .side-menu-brand {
        line-height: $navbar-height;
        font-size: 18px;
        overflow: hidden;
        display: block;
        width: 100%;
        height: $navbar-height;
        padding: 0;
        text-decoration: none;

        .title {
          display: inline-block;
          margin-left: -10px;
        }
      }
    }

    .side-menu-logo {
      display: inline-block;
      width: $navbar-height;

      .img-full {
        display: inline-block;
        width: 200px;
        height: 32px;
        margin: 14px;
      }

      .img-min {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin: 14px;
      }
    }

    .side-menu-nav {
      padding-top: 20px;

      ul {
        position: relative;
        padding: 0;
        display: block;

        li {
          position: relative;
          display: block;
          overflow: hidden;
          width: 100%;

          a {
            display: block;
            padding: 10px 20px;
            white-space: nowrap;
            font-size: 1em;

            .title {
              white-space: nowrap;
              padding-left: 6px;
            }
          }

          a:hover,
          a:focus {
            text-decoration: none;
          }

          ul {
            position: relative;
            background: darken($side-menu-bg, 4%);

            li {
              padding-left: 20px;

              &::after {
                position: absolute;
                left: 26px;
                top: 0px;
                height: 100%;
                width: 1px;
                background: #fff;
                content: "";
              }

              a {
                font-size: 0.9em;
                position: relative;

                &::before {
                  position: absolute;
                  left: 6px;
                  top: 19px;
                  height: 1px;
                  width: 8px;
                  background: #fff;
                  content: "";
                }

                span.title {
                  position: relative;

                  &::after {
                    position: absolute;
                    left: auto;
                    top: auto;
                    height: auto;
                    width: auto;
                    background: transparent;
                    content: "";
                  }
                }
              }
            }

            li.active {
              a {
                background: transparent;
              }
            }
          }

          li:last-child {
            &::after {
              position: absolute;
              left: 26px;
              top: 0px;
              height: 20px;
              width: 1px;
              background: #fff;
              content: "";
            }
          }
        }

        li.dropdown > a:after {
          font-family: FontAwesome;
          position: absolute;
          right: 1em;
          content: '\f107';
        }

        li.dropdown > a[aria-expanded=true]:after {
          content: '\f106';
        }

        li.active-self {
          background-color: darken($side-menu-bg-dark, 5%);
        }
      }
    }
  }

  .content-container {
    margin-right: 15px;
    margin-left: 260px;
    padding-top: 70px;
  }
}

.app-container.collapsed {
  .navbar-expand-toggle {
    @extend .fa-rotate-90;
  }

  .navbar-top {
    padding-left: 60px;
  }

  .side-menu {
    width: 60px;

    .side-menu-header {
      .side-menu-brand {
        .title {
          display: none;
        }
      }
    }

    .side-menu-nav {
      ul {
        li {
          a {
            .title {
              display: none;
            }
          }
        }

        li.dropdown > a:after {
          content: ' ';
        }

        .collapse {
          display: none;
        }
      }
    }
  }

  .content-container {
    margin-left: 75px;
  }
}

@media (max-width: $screen-xs-max) {
  .app-container {
    .side-menu {
      display: fixed;
    }

    .content-container {
      margin-left: 15px;
    }

    .navbar-top {
      height: auto;
    }

    .navbar-top,
    .navbar-top .navbar-header,
    .navbar-top .navbar-collapse {
      min-width: 320px;
    }
  }

  .app-container.collapsed {
    .side-menu {
      width: 0;
    }

    .content-container {
      margin-left: 15px;
    }

    .navbar-top {
      padding-left: 0px;
      height: auto;
    }
  }
}

.loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  text-align: center;

  .overlay {
    background-color: #fff;
    position: absolute;
    opacity: 0.9;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .icon {
    position: absolute;
    top: 50%;
    color: $gray-light;
    font-size: 2.5em;
    opacity: 0.8;
  }
}

/**
 * 
 * Tema
 * - Cores
 * 
 */

.btn-delete {
  @extend .btn-danger;
}

.app-container {
  .side-menu {
    background-color: $side-menu-bg;

    .side-menu-header {
      background-color: $brand-primary;

      a {
        color: $side-menu-header-color;
      }
    }

    .side-menu-nav {
      a {
        color: $side-menu-color;
      }
    }
  }
}

//MODAL FILE MANAGER
.modal-backdrop.in {
  opacity: .8 !important;
  z-index: 10002 !important;
}

.modal-lg {
  width: 100% !important;
  height: 100% !important;
  margin: 0px auto !important;
}

.modal {
  z-index: 10050 !important;
  padding: 0px;

  .modal-body {
    padding: 0px;
  }

  h4 {
    border-bottom: 0px solid #00afef;
    background-color: transparent !important;
    padding: 0px;
    margin-top: 0px;
    width: 100%;
    text-align: center;
    font-style: normal;
    color: #666666;
  }

  .padding-20 {
    padding: 0px 0px;
  }

  .row {
    margin-left: -20px;
    margin-right: -20px;
    max-width: 2000px;
  }

  .control-bar {
    height: 8% !important;
  }

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;

    .close {
      opacity: 1 !important;
      color: #000;
      text-shadow: 0 0px 0 #fff;
      font-size: 28px;
    }
  }

  .folder-container .right-folders .file-content .file-name {
    word-break: break-word;
  }

  .btn {
    font-size: 15px;
  }

  .app-container {
    .control-bar {
      position: fixed;
      bottom: 50px;
      right: 0px;
      height: 0% !important;
    }

    .padding-20 {
      padding: 0px;
    }

    .listed-folder {
      color: #F5D36E;

      folder {
        color: #333333 !important;
      }
    }

    h4 {
      color: #666666;
      padding: 0px;
      width: 100%;
      margin-top: 60px;
      text-align: center;
      display: block;
    }

    .folder-container {
      .left-folders {
        color: #000;
        background-color: #fff;
        border-right: 2px solid #EEEEEE;
        overflow: auto;

        .folder-row {
          padding-right: 0px;
          padding-left: 10px;
        }

        .folder-row folder {
          margin-left: 2px !important;
          font-size: 13px;
        }
      }

      .right-folders {
        .selected {
          background-color: #CCE8FF;
          border: 2px solid #99D1FF;
        }

        .box-imagem {
          height: 85px;
          overflow: hidden;
          display: block;
        }

        .file-content {
          margin-right: 10px;
          min-height: 82px !important;
          width: 184px !important;
          height: 115px;

          .file-name {
            font-style: normal;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.modal-body {
  height: 100%;
  padding: 0px;

  img {}
}

/**
 * loading spinner
 */

.fa-refresh-animate {
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }

  to {
    transform: scale(1) rotate(360deg);
  }
}

/**
 * Responsive Table
 */

@media (max-width: 632px) {
  .table-responsive {
    thead {
      display: none;
    }

    tr:nth-of-type(odd) {
      background: #eee;
    }

    td,
    tr {
      display: block;
    }

    td:before {
      content: attr(data-title);
      padding-right: 10px;
    }

    tr {
      margin-bottom: 30px;
    }
  }
}

/**
 * 
 */

@import "../../../../../atlas-framework-forms/src/resources/assets/sass/forms";
